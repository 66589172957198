import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  SiteFooterLayout, LayoutShell, LangStringsProvider, ViewProvider, useLang, 
  TrendCard, TopicCard, SubTopicCard, EntityCard, PageCard, EmbedComponentsProvider, 
  createEmbed, useEmbedComponents, OutlineEntityDotLink, EntityDotLink, 
  useReady, CitationCard, PersonValue, OrganisationValue, EntityCell, EntityTypeBannersProvider, 
  TopicExpertiseValue, useContributeForm, PersonBanner, UiProvider, UiConfig, 
  FeedEntryCard,
  PageHalfBanner,
  withLightMode,
  EntityExplorerLayoutWithoutBanner,
  UserMenuProvider,
  EntityExplorerLayout,
  OnboardingExpertiseLevelProvider,
  FileAssetCard,
  EntityContentEmbed,
  FileAssetCardRow,
} from '@shapeable/ui';

import * as pageLayouts from './page-layouts';
import { LANG_STRINGS } from '../lang';
import { AQUA_BLUE, BRIGHT_MENU_BAR_OVERLAY, GESDA_PURPLE, LayoutStyles, themeOverrides } from '../theme';
import { SubTopicsProvider } from '../gatsby/providers/sub-topics-provider';
import { TopicsProvider } from '../gatsby/providers/topics-provider';
import { TrendsProvider } from '../gatsby/providers/trends-provider';
import { TrendsRadarProvider } from '../gatsby/providers/trends-radar-provider';
import { TrendsExplorerProvider } from '../gatsby/providers/trends-explorer-provider';
import { PagesProvider } from '../gatsby/providers/pages-provider';
import { EditionsProvider } from '../gatsby/providers/editions-provider';
import { GptConfig, GptProvider } from '@shapeable/gpt';
import { RadarConfig, RadarProvider } from '@shapeable/gesda-ui';
import { DebateCard } from './entities/debate-card';
import { OrganisationsProvider } from '../gatsby/providers/organisations-provider';
import { PeopleProvider } from '../gatsby/providers/people-provider';
import { FeedEntriesProvider } from '../gatsby/providers/feed-entries-provider';
import { FeedEntryTypesProvider } from '../gatsby/providers/feed-entry-types-provider';
import { EventsProvider } from '../gatsby/providers/events-provider';
import { EventBlockquote } from './entities/event-blockquote';
import { MapConfig, MapProvider, MapState } from '@shapeable/maps';
import { DEFAULT_MAP_ENTITY_TYPE_NAMES, utlityMenuItems } from '../data';
import { CitationIcon, PersonIcon } from '@shapeable/icons';
import { SidebarButtons } from './elements/sidebar-buttons';
import { EntityMapPanel } from './entities/entity-map-panel';
import { EventCard } from './entities/event-card';
import { EntityTypesProvider } from '../gatsby/providers/entity-types-provider';
import { SubTopicOutlineDotLink } from './entities/sub-topic-outline-dot-link';
import { LinkCard } from './entities/link-card';
import { OutlineEntityLinkEmbed } from './entities/outline-entity-link-embed';
import { VideosProvider } from '../gatsby/providers/videos-provider';
import { TopicBannerLayout } from './entities/topic-banner-layout';
import { PersonCard } from './entities/person-card';
import { ContributeFormPanel } from './elements/contribute-form-panel';
import { CitationsProvider } from '../gatsby/providers/citations-provider';
import { PersonTooltip } from './entities/person-tooltip';
import * as EntityPageQueries from '../graphql/details';
import { UserMenuSpaceItem } from './elements/user-menu-space-item';



const initialMapState: MapState = {
  isVisible: false,
  isInitialized: false,
  zoom: 2,
  entityTypeNames: DEFAULT_MAP_ENTITY_TYPE_NAMES,
  latitude: 46.8182,
	longitude: 8.2275,
};

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

const PersonIconMapStyles = breakpoints({
  base: css`
    ${theme.FILL('bright')}
    background-color: ${theme.COLOR('dark')};
    border-radius: 50%;
  `,
});

const ContributeFormStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 800;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});

const MapStyles = breakpoints({
  base: css`
    
  `,
});

const PageCardEmbedStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)} 0 ${theme.UNIT(10)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,
    PersonIconMap: styled(PersonIcon)`${PersonIconMapStyles}`,
    Map: styled(EntityMapPanel)`${MapStyles}`,
    ContributeForm: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormStyles}`,

    PageCardEmbed: styled(EntityCell)`${PageCardEmbedStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const [isOnboardingLayout, setIsOnboardingLayout] = React.useState(false);

  React.useEffect(() => {
    setIsOnboardingLayout(window.location.pathname === '/app/welcome');
  }, []);

  const embedComponents = useEmbedComponents();

  const contributeForm = useContributeForm();

  const t = useLang();

  const { isReady } = useReady();

  const mapConfig: MapConfig = {
    initialMapState,
    availableEntityTypeNames: ['Person', 'Organisation'], 
  }

  const gptConfig: GptConfig = {
    title: 'Radar AI',
    autoPrompts: [
      {
        id: 'gesda', 
        type: 'text',
        values: { question: t("Tell me more about GESDA") },
      },
      {
        id: 'purpose',
        type: 'text',
        values: { question: "What is the purpose of GESDA?" },
      },
    ]
  };

  const config: UiConfig = {
    sidebar: {
      isAvailable: () => true,
      showFullscreenButton: true,
      buttons: <SidebarButtons />,
      color: '#292929',
    },
    header: {
      showThemeMode: true,
      boundary: 'padding',
      utilityMenu: utlityMenuItems,
      showUtilities: true,
    },
    explorer: {
      defaultView: 'radar',
      authenticationRequired: false,
    },
    componentProps: {
      TaskItemLayout: {
        hideContent: false,
      },
      MenuItem: {
        colors: {
          hover: {
            text: 'strong',
            background: GESDA_PURPLE,
          }
        }
      },
      MenuItemLink: {
        colors: {
          active: {
            text: AQUA_BLUE,
          }
        }
      },
      SessionLayout: {
        photoStyle: 'original',
      },
      ExplorerEntityDetailsLayout: {
        stickyTopOffset: 80,
        isAsideSticky: true,
        asideIsOffset: false,
      },
    },
    menuBar: {
      boundary: 'padding',
      delay: 0,
      slideDownDuration: 0,
      showShadow: false,
    },
    theme: themeOverrides,
    layouts: {
      pageLayouts, 
      defaultPageLayout: pageLayouts.PageLayoutDefault,
    },
    search: {
      placeholder: 'Search GESDA Radar',
      isAvailable: true,
      
    },
    entity: {
      dataProviders:  [
        SubTopicsProvider, TopicsProvider, TrendsProvider,
        TrendsRadarProvider, TrendsExplorerProvider, PagesProvider, OrganisationsProvider,
        PeopleProvider, FeedEntriesProvider, FeedEntryTypesProvider, EventsProvider, EntityTypesProvider,
        VideosProvider, EditionsProvider, CitationsProvider,
      ],
      layouts: {
        Person: EntityExplorerLayoutWithoutBanner,
        Space: EntityExplorerLayout,
      },
      labels: {
        Trend: 'Scientific Platform',
        Topic: 'Emerging Topic',
        SubTopic: 'Sub-Field',
      },
      cards: {
        Trend: TrendCard,
        Topic: TopicCard,
        Post: EntityCard,
        SubTopic: SubTopicCard,
        Page: PageCard,
        FeedEntry: FeedEntryCard,
        Opportunity: EventCard,
        Debate: DebateCard,
        Lens: EntityCard,
        InvitedContribution: EntityCard,
        DeepDive: EntityCard,
        Citation: CitationCard,
        Link: LinkCard,
        Person: PersonCard,
        // FileAsset: FileAssetCardRow,
      },
      pageQueries: EntityPageQueries,
      tooltips: {
        Person: PersonTooltip,
      },
      values: {
        Trend: OutlineEntityDotLink,
        Topic: OutlineEntityDotLink,
        SubTopic: SubTopicOutlineDotLink,
        Person: PersonValue,
        Page: EntityDotLink,
        TopicExpertise: TopicExpertiseValue,
        Organisation: OrganisationValue,
        Event: OutlineEntityDotLink,
      },
      icons: {
        // Person: My.PersonIconMap,
        Edition: CitationIcon,
      },
    },
  };

  const radarConfig: RadarConfig = {
    topicNodes: {
      "human-applications-of-genetic-engineering": {
        offsetY: -20,
        offsetX: 30,
        textAnchor: 'start',
      },
      "decarbonisation": {
        offsetY: 20,
        offsetX: -50,
      },
      "advanced-therapeutics": {
        offsetY: -40,
        offsetX: 0,
        textAnchor: 'middle',
      },
      "quantum-computing": {
        offsetY: 35,
        offsetX: 30,
      },
      "future-therapeutics": {
        offsetY: -38,
        offsetX: 0,
        textAnchor: 'middle',
      },
      "future-food-systems": {
        offsetY: 0,
        offsetX: -25,
        textAnchor: 'end',
      },
      "future-of-consciousness": {
        offsetY: -20,
        offsetX: 20,
        textAnchor: 'start',
      },
      "synthetic-biology": {
        offsetY: 35,
        offsetX: -20, 
      },
      "orbital-environment": {
        offsetY: 35,
        offsetX: 0,
        textAnchor: "middle", 
      },
      "science-of-the-origins-of-life": {
        offsetY: -55,
        offsetX: -30,
        textAnchor: 'start', 
      },
      "advances-science-diplomacy": {
        offsetY: 5,
        offsetX: -25, 
        textAnchor: 'end',
      },
      "synthetic-biology-1": {
        offsetY: 35,
        offsetX: -20, 
      },
      "solar-radiation-modification": {
        offsetY: -10,
        offsetX: -25, 
        textAnchor: 'end',
      },
      "ocean-science": {
        offsetY: 35,
        offsetX: 0,
        textAnchor: "middle",
      },
      "digital-security": {
        offsetX: -25,
        offsetY: 0,
        textAnchor: "end",
      },
      "infectious-diseases": {
        offsetY: -25,
        offsetX: -20,
        textAnchor: 'end',
      },

      "space-resources": {
        offsetY: -10,
      },
      "organoids": {
        offsetY: 35,
        offsetX: 32,
      },
      "earth-systems-modelling" : {
        offsetY: -38,
        offsetX: -20,
      },
      "unconventional-computing": {
        offsetY: 10,
      },
      "healthspan-extension": {
        offsetY: 0,
        offsetX: 25,
        textAnchor: 'start',
      },
      "democracy-affirming-technologies": {
        offsetY: 20,
        offsetX: 0,
        textAnchor: 'middle',
      },
      "archaeology": {
        textAnchor: 'middle',
        offsetY: -25,
        offsetX: 20,
      },

    },
    viewboxPresets: {
      "human-augmentation": {
        x: 63.33192511596794,
        y: -24.16983724359839,
        width: 516.0960000000001,
        height: 516.0960000000001,
        zoom: 0.612
      },

      "eco-regeneration-geoengineering": {
        x: 482.24218418554875,
        y: 3.2851691647673786,
        width: 516.0960000000001,
        height: 516.0960000000001,
        zoom: 0.612
      },
      
      "science-and-diplomacy": {
        x: 483.12782955355846,
        y: 456.73559758680847,
        width: 516.0960000000001,
        height: 516.0960000000001,
        zoom: 0.612
      },

      "knowledge-foundations": {
        x: 240.46099871832624,
        y: 571.8694954283408,
        width: 516.0960000000001,
        height: 516.0960000000001,
        zoom: 0.612
      },

      "quantum-revolution-and-advanced-ai": {
        x: -1.3201867488945305,
        y: 369.05670615363897,
        width: 516.0960000000001,
        height: 516.0960000000001,
        zoom: 0.612
      },
    }
  };

  return (
    <LangStringsProvider value={LANG_STRINGS}>
    <ViewProvider>
    <UiProvider value={config}>
    <GptProvider value={gptConfig}>
    <RadarProvider value={radarConfig}>
    <OnboardingExpertiseLevelProvider value={{
      expertiseLevel: 'interested-novice',
    }}>
    <UserMenuProvider value={{
      userMenuBottomItem: UserMenuSpaceItem,
    }}>
    <EntityTypeBannersProvider value={{
      Topic: TopicBannerLayout,
      Person: PersonBanner,
      Event: withLightMode(PageHalfBanner),
      Page: withLightMode(PageHalfBanner),
      Space: withLightMode(PageHalfBanner),
    }}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
      blockquote: EventBlockquote,
      Trend: createEmbed('trends', OutlineEntityLinkEmbed),
      Topic: createEmbed('topics', OutlineEntityLinkEmbed),
      SubTopic: createEmbed('subTopics', OutlineEntityLinkEmbed),
      Page: createEmbed('pages', My.PageCardEmbed, (props) => ({ style: { textDecoration: 'none', hyphens: 'none',}})),
      }}>
      <MapProvider value={mapConfig}>
      <My.Container
        className={className}
        hideOverflow={false}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
          className: 'shp--react-tooltip',
        }}
      >
        { 
          isReady && <My.Map /> 
        }
        <My.ContributeForm isVisible={contributeForm.isVisible} />
        <My.Body>
          {children}
        </My.Body>
        {
          isOnboardingLayout && 
          <My.Footer  />
        }
      </My.Container>
      </MapProvider>
      </EmbedComponentsProvider>
      </EntityTypeBannersProvider>
      </UserMenuProvider>
      </OnboardingExpertiseLevelProvider>
    </RadarProvider>
    </GptProvider>
    </UiProvider>
    </ViewProvider>
    </LangStringsProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;