import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityCard, EntityCardDefaultProps, EntityCardProps } from '@shapeable/ui';
const cls = classNames('trend-card');

// -------- Types -------->

// -------- Props -------->

export type LinkCardProps = EntityCardProps & { 
};

export const LinkCardDefaultProps: Omit<LinkCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  useEntityColorForBackground: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const LinkCard: Shapeable.FC<LinkCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;

  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      showExternalLink={false}
      colors={{ background: 'brightWarm' }}
      entity={entity} 
    />
  )
};

LinkCard.defaultProps = LinkCardDefaultProps;
LinkCard.cls = cls;