import { breakpoints, ThemeDefinition, theme } from "@shapeable/theme";
import { WARM_GREY } from "@shapeable/ui";
import { css } from "styled-components";

export const YELLOW = '#FFCA04';
export const YELLOW_DARK = '#FCB10F';
export const YELLOW_ORANGE = '#F79616';
export const AQUA_BLUE = '#1BB1AB';
export const GESDA_PURPLE = '#623B70';

export const CHART_BG = '#EBEBEB';

export const BRIGHT_MENU_BAR_OVERLAY = `#FFFFFFFA`;

export const themeOverrides: ThemeDefinition = {
  light: {
    color: {
      primary: "#1BB1AB",
      link: AQUA_BLUE,
    },
  },
  font: {
    family: {
      serif: 'Aleo',
      sans: 'Montserrat',
      heading: 'Aleo',
      copy: 'Montserrat',
    }
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};

    .shp--entity-gpt-layout {
      margin-top: -20px;

      .shp--gpt-output-header {
        padding-top: ${theme.UNIT(4)};
      }

      .shp--gpt-output-content,
      .shp--gpt-output-header {
        padding-left: ${theme.UNIT(2)};
        padding-right: ${theme.UNIT(2)};
      }

      .shp--gpt-generated-content {
        padding-left: 0;
        padding-right: 0;
      }

      .shp--prompt-template-form {
        padding-left: ${theme.UNIT(1)};
        padding-right: ${theme.UNIT(1)};
      }

    }
    
    .shp--site-menu-bar {
      backdrop-filter: blur(1px);
      ${theme.HOVER_SHADOW()};
      border-bottom: 1px solid ${theme.COLOR('line-very-subtle')};
      h1 {
        font-size: 15px;
        color: ${theme.COLOR('strong')};
      }
    }


    .shp--organisation-powered-by {
      opacity: 0.6;

      img {
        mix-blend-mode: overlay;
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }

    .shp--video-image {
      width: 100%;
      max-width: 800px;
    }

    .shp--content {

      img {
        max-width: 100%;
      }
    }

    .mapboxgl-marker .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      display: block;
      img {
        object-fit: cover !important;
        padding: 0 !important;

      }
    }

    .shp--markdown-embed .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      img {
        object-fit: cover !important;
        height: auto !important;
      }
    }
    
    .gatsby-image-wrapper [data-placeholder-image] {
      transition: none !important;
      opacity: 0 !important;
    }

    .gatsby-image-wrapper [data-main-image] {
      transition: none !important;
      opacity: 1 !important;
    }

    .shp--slice-intro {
      font-size: 1.75em;
      text-align: left;
      line-height: 1.25em;
    }

    .shp--slice-section-color-block {
      max-height: 600px;
    }

    .shp--person-bio-cell {
      img,
      .gatsby-image-wrapper img {
        object-fit: cover !important;
      }
    }

  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
  
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(16)};
    .shp--content {
      img {
        max-width: 100%;
      }
    }

  `,
});