import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MenuItem, UiComponentName, useActivePerson, useComponentProps, useLink, useReady } from '@shapeable/ui';
import { ChallengeIcon } from '@shapeable/icons';
import { Person, Space } from '@shapeable/gesda-types';


const cls = classNames('user-menu-space-item');

// -------- Types -------->

// -------- Props -------->

export type UserMenuSpaceItemProps = Classable & HasChildren & {
}

export const UserMenuSpaceItemDefaultProps: UserMenuSpaceItemProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  NAME: 'UserMenuSpaceItem' as UiComponentName,

  Container: styled(MenuItem)<ContainerProps>`${ContainerStyles}`,
};

export const UserMenuSpaceItem: Shapeable.FC<UserMenuSpaceItemProps> = (props) => {
  const { 
    className, children, 
  } = useComponentProps(My.NAME, UserMenuSpaceItemDefaultProps, props) as UserMenuSpaceItemProps;

  const { person: activePerson } = useActivePerson(
    {
      queryExtraFields: `
        availableSpaces {
          id name title slug path sitePath
        }
      `
    }
  ) as any;

  const { availableSpaces = [] } = activePerson || {} as Person;

  const { navigate } = useLink();

  const handleSpacesNavigation = (space: Space) => {
    navigate(space.sitePath);
  };

  const userCanView = !!availableSpaces.length;
  
  return (
    <>
      {
        userCanView &&
        availableSpaces.map((space: Space) => (
          <My.Container 
            key={space.id}
            className={cls.name(className)}
            onClick={() => { handleSpacesNavigation(space) }}
            iconComponent={ChallengeIcon}
            label={space.title || space.name}
          />
        ))
      }
    </>
  )
};

UserMenuSpaceItem.cls = cls;