import React from 'react';
import styled, { css } from 'styled-components';
import { ChatAutoMessagePrompt, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ChatProvider, ComponentPropsProvider, createEmbed, EmbedComponentsProvider, EntityConversationLayout, EntityConversationsLayout, EntityProvider, ImageAsset, ImageAssetEmbed, PageHeader, PageLayoutStandard, SiteHeaderLayout, SiteHeaderProvider, SliceLayoutBoundary, useEntity, useLang } from '@shapeable/ui';
import { GptLayout } from '@shapeable/gpt';
import { ChevronLeftIconGlyph, IconSheet } from '@shapeable/icons';
import { EntityTitleHeader } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';

const cls = classNames('page-layout-conversations');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background-color: ${theme.COLOR('very-subtle')};\

    .page-header__Body-krBogA {
      display: none;
    }
  `,
});

const GptStyles = breakpoints({
  base: css`
    
  `,
});

const ConversationStyles = breakpoints({
  base: css`
    height: 90vh;
    margin: 50px 0;
  `,
});

const ImageAssetStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)<ContainerProps>`${ContainerStyles}`,

    ChevronLeft: styled(ChevronLeftIconGlyph),

    Conversations: styled(EntityConversationLayout)`${ConversationStyles}`,
    ImageAsset: styled(ImageAssetEmbed)`${ImageAssetStyles}`,
};

export const PageLayoutConversations: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  return (
    <ComponentPropsProvider value={{
      SiteHeaderLayout: {
        colors: {
          background: '#0AA288',
        }
      }
    }}>
      <SiteHeaderLayout />
      <SliceLayoutBoundary>
      <My.Conversations entity={entity}  />
      </SliceLayoutBoundary>
     
    </ComponentPropsProvider>
  )
};