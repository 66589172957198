import { Page, Event } from "@shapeable/gesda-types";

export type ExplorerView = 'ai' | 'user' | 'radar' | 'pulse-of-science' | 'pulse-of-diplomacy' | 'pulse-of-impact' | 'pulse-of-society' | 'community' | 'resources';
export type TrendView = 'radar-ai' | 'science' | 'diplomacy' | 'impact' | 'society';
export type TopicView = 'radar-ai' | 'overview' | 'connections' | 'resources' | 'contributors';
export type SubTopicView = 'radar-ai' | 'overview' | 'resources';
export type EventView = 'radar-ai' | 'key-takeaways' |'overview' | 'sessions' | 'resources';

export type Debate = Page & {
};

export type DeepDive = Page & {
};

export type InvitedContribution = Page & {
};

export type Lens = Page & {
};

export type Opportunity = Event & {
};

export type Workshop = Event & {
};

const isPageType = (entity: Page, type: string) => {
  return entity.type?.slug === type || entity.types?.[0]?.slug === type;
};

const isEventType = (entity: Event, type: string) => {
  return entity.type?.slug === type || entity.types?.[0]?.slug === type;
}

// type guards
export const isDebatePage = (entity: Page): entity is Debate => isPageType(entity, 'debate');
export const isDeepDivePage = (value: Page): value is DeepDive => isPageType(value, 'deep-dive');
export const isInvitedContribution = (value: Page): value is InvitedContribution => isPageType(value, 'invited-contribution');
export const isLensPage = (value: Page): value is Lens => isPageType(value, 'lens');
export const isOpportunityEvent = (entity: Event): entity is Opportunity => isEventType(entity, 'opportunity');
export const isWorkshopEvent = (value: Event): value is Workshop => isEventType(value, 'workshop');

