import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Page } from '@shapeable/gesda-types';
import { SliceLayoutBoundary, SiteHeaderLayout, EntityBannerProps, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutProps, ComponentPropsProvider, PAGE_HOME, BackButtonEntityProvider, SvgCanvasViewBoxDefinition, DownloadButton, useLang, ExplorerTabs } from '@shapeable/ui';
import { PageMetaLayout } from './page-meta-layout';
import { useRadar } from '@shapeable/gesda-ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { RadarChartMain } from '../elements/radar-chart-main';
import { RadarChart } from '../elements/radar-chart';
const cls = classNames('page-explorer-layout');

// -------- Types -------->

type RadarProps = {
  isInteractive: boolean;
}

// -------- Props -------->

export type PageExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & {  
  entity?: Page;
  bannerProps?: Omit<EntityBannerProps, 'entity'>;
  showRadar?: boolean;
  isAsideSticky?: boolean;
};

export const PageExplorerLayoutDefaultProps: Omit<PageExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
  isAsideSticky: false,
  showRadar: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});


const RadarChartStyles = breakpoints({
  base: css`

    ${({ isInteractive }: RadarProps ) => !isInteractive && css`
      margin-top: ${theme.UNIT(3)};
      opacity: 0.4;
      filter: saturate(0.4);
    `}
  `,
});

const DownloadButtonStyles = breakpoints({
  base: css`
    max-width: 460px;
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
      Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Radar: styled(RadarChart)<RadarProps>`${RadarChartStyles}`,

      DownloadButton: styled(DownloadButton)`${DownloadButtonStyles}`,
};

export const PageExplorerLayout: Shapeable.FC<PageExplorerLayoutProps> = (props) => {
  const { className, children, entity, bannerProps, showRadar, isAsideSticky, ...explorerEntityDetailsProps } = props;

  const radar = useRadar();

  const t = useLang();

  let initialViewBox: SvgCanvasViewBoxDefinition;
  const trend = entity.trend;

  if (trend) {
    initialViewBox = radar.viewboxPresets?.[trend.slug];
  }

  const hasFile = !!entity.files.length
  const url = entity.files[0]?.file?.url || "/pdf";
  const downloadUrl = url.replace(/upload\//, 'upload/fl_attachment/');

  const afterIntroMeta = hasFile ? (
    <My.DownloadButton shape='rounded' href={downloadUrl}>
      {t('Download as Extended Version PDF')}
    </My.DownloadButton>
  ) : null;

  return (
    <ExplorerProvider>
    <BackButtonEntityProvider value={entity.parent}>
    <My.Container>
      <ComponentPropsProvider value={{
        EntityBanner: {
          variant: 'image',
          showSubheader: true,
          useBackgroundColor: false,
          entityValueProps: { showImage: false, showOutlineNumber: false }
        },
        PageViewLayout: {
          afterIntro: afterIntroMeta
        }
      }}>
      <My.Header />
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
        <My.Layout 
          entity={entity}
          className={cls.name(className)}
          {...explorerEntityDetailsProps}
          asideMaxWidth={800}
          isAsideSticky={false}
          asideIsOffset={false}

          dataVizMain={
            <RadarChartMain />
          }

          dataViz={
            showRadar && (
              !initialViewBox ? 
              <RadarChart /> :
              <My.Radar 
                fadeSize={!!initialViewBox && 44} 
                initialViewBox={initialViewBox} 
                isInteractive={!initialViewBox}
              />
            )
          }
          metaHead={
            <PageMetaLayout
              entity={entity} />
          }
        />
        </SliceLayoutBoundary>
      </ComponentPropsProvider>
    </My.Container>
    </BackButtonEntityProvider>
    </ExplorerProvider>
  )
};

PageExplorerLayout.defaultProps = PageExplorerLayoutDefaultProps;
PageExplorerLayout.cls = cls;